// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("@/views/pages/index.vue");
const WebhookInvoice = () => import("@/views/pages/webhookInvoice.vue");
const FailInvoice = () => import("@/views/pages/fail.vue");
const Basket = () => import("@/views/pages/basket.vue");
const SearchOrder = () => import("@/views/pages/searchOrder.vue");
const Outlets = () => import("@/views/pages/outlets.vue");
const Checkout = () => import("@/views/pages/checkout.vue");
const History = () => import("@/views/history/index.vue");
const Quality = () => import("@/views/quality_standards/index.vue");
const Privacy = () => import("@/views/privacy/index.vue");
const Terms = () => import("@/views/terms/index.vue");
const NotFound = () => import("@/views/NotFound.vue");
const Hug = () => import("@/views/social_responsibility/index.vue");
const AddonPage = () => import("@/views/addon/index.vue");
const Signup = () => import("@/views/signup/index.vue");
const Password = () => import("@/views/password_recovery/index.vue");
const HR = () => import("@/views/hr/index.vue");

const routes = [
  {
    path: "/",
    name: "root",
    redirect: { name: "home" },
  },
  {
    path: "/index",
    name: "home",
    component: Home,
  },
  {
    path: "/webhookInvoice/:paymentName",
    name: "webhookInvoice",
    component: WebhookInvoice,
  },
  {
    path: "/fail",
    name: "fail",
    component: FailInvoice,
  },
  {
    path: "/search-order",
    name: "searchOrder",
    component: SearchOrder,
  },
  {
    path: "/hr",
    name: "hr",
    component: HR,
  },
  {
    path: "/basket",
    name: "basket",
    component: Basket,
  },
  {
    path: "/outlets",
    name: "outlets",
    component: Outlets,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/history",
    name: "history",
    component: History,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/quality_standards",
    name: "quality_standards",
    component: Quality,
  },
  {
    path: "/social_responsibility",
    name: "social_responsibility",
    component: Hug,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: Password,
  },
  {
    path: "/addon",
    name: "addon-page",
    component: AddonPage,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0, behavior: "smooth" };
  },
});

// router.beforeResolve(async (to, from, next) => {
//   // this.$gtag.pageview(to.name)
//   // or use to.fullPath
//   return next()
// })

export default router;
