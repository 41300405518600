<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_right">
    <div class="top">
      <div class="arrow" @click="togglePasswordMode">
        <img src="@/assets/images/arrow-forward.svg" alt="arrow" />
      </div>
      <div class="wrapper">
        <div class="title">
          {{ $t('changePassword') }}
        </div>
        <el-form @submit.prevent="handleSubmit(refForm)"  ref="refForm" class="__kfc_login_form __kfc_form" label-position="top" label-width="100px" :rules="rules"
          :model="form" status-icon>
          <el-form-item :label="$t('password')" class="form_item" prop="password">
            <el-input type="password" class="__kfc_r_8" v-model="form.password" :placeholder="$t('password')"
              show-password />
          </el-form-item>

          <el-form-item :label="$t('repeatPassword')" class="form_item" prop="confirm_password">
            <el-input type="password" class="__kfc_r_8" v-model="form.confirm_password"
              :placeholder="$t('repeatPassword')" show-password />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <button type="button" @click="handleSubmit(refForm)">
        {{ $t('save') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.__kfc_popup_right {
  .top {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .title {
        color: $darkMain;
        font-family: Oswald;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        /* 125% */
        text-transform: uppercase;
      }

      .form-wrap {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .form {
          display: flex;
          flex-direction: column;
          gap: 8px;

          label {
            color: $dark40;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            /* 123.077% */
            letter-spacing: -0.3px;
          }

          .field {
            display: flex;
            width: 100%;
            position: relative;

            .control {
              width: 100%;

              input {
                width: 100%;
              }
            }

            .eye-button {
              position: absolute;
              right: 16px;
              top: 0;
              bottom: 0;
              margin: auto;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 0 40px;

    button {
      width: 100%;
      border: none;
      background: $redMain;
      color: $whiteMain;
      border-radius: 4px;
      padding: 16px 30px;
    }
  }
}
</style>

<script>
import Req from "@/utils/Request";
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  props: [
    'togglePasswordMode',
    'togglePassword',
    'toggleProfileMode'
  ],
  setup (props) {
    const { togglePasswordMode, togglePassword, toggleProfileMode } = props;
    const store = useStore();
    const getUser = computed(() => store.getters['user/getUser']);
    const locale = computed(() => store.getters['locale']);
    const refForm = ref(null);
    const router = useRouter()

    const form = reactive({
      password: '',
      confirm_password: '',
    });
    const rules = reactive({
      password: [{
        required: true,
        message: locale.value == 'mn'
          ? 'Нууц үг заавал байх ёстой'
          : 'Нууц үг заавал байх ёстой',
        trigger: 'blur'
      }],
      confirm_password: [{
        required: true,
        message: locale.value == 'mn'
          ? 'Нууц үг заавал байх ёстой'
          : 'Нууц үг заавал байх ёстой',
        trigger: 'blur'
      }],
    });
    const handleSubmit = async (formEl) => {
      if (!formEl)
        return;
      await formEl.validate(async (valid) => {
        if (valid) {
          const payload = {
            password: form.password,
            confirm_password: form.confirm_password,
          }
          const { data: result } = await Req({ url: '/manager/setPassword', body: payload, jwtToken: getUser.value.access_token })
          if (result.success) {
            store.dispatch('user/deleteUser');
            router.push('/');
            toggleProfileMode();
          }
        }
      });

    }
    return {
      form, rules, locale, refForm, handleSubmit, togglePasswordMode, togglePassword
    }
  }
};
</script>
