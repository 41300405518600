export const formatDateFull = (string) => {
  const date = new Date(string);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  month = month.toString().length > 1 ? month : '0' + month;
  day = day.toString().length > 1 ? day : '0' + day;
  hours = hours.toString().length > 1 ? hours : '0' + hours;
  minutes = minutes.toString().length > 1 ? minutes : '0' + minutes;

  return year + '-' + month + '-' + day + ' (' + hours + ':' + minutes + ')';
};
export const formatDate = () => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month.toString().length > 1 ? month : '0' + month;
  day = day.toString().length > 1 ? day : '0' + day;

  return year + '-' + month + ':' + day;
};
