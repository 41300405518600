<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <div class="header_wrapper">
      <div class="left-wrap __kfc_r_8">
        <router-link to="/">
          <div class="item">{{ $t('menu') }}</div>
        </router-link>
        <router-link to="/outlets">
          <div class="item">{{ $t('branch') }}</div>
        </router-link>
        <router-link to="/search-order">
          <div class="order-wrap">
            <img src="@/assets/icons/orderCar.png" alt="" />
            <span> {{ $t("trackOrder") }}</span>
          </div>
        </router-link>
      </div>
      <div class="header_layout_logo">
        <router-link to="/" rel="noopener noreferrer">
          <img src="@/assets/images/logo_header.png" alt="logo" />
        </router-link>
      </div>
      <div class="right-wrap">
        <div class="right-items">
          <div id="kfc_login_but" v-if="getUser.name === '' && getUser.phone === ''" class="__kfc_r_8 login"
            @click="loginPopup">
            <img src="@/assets/images/user.png" alt="" />
            <span>{{ $t('login') }}</span>
          </div>
          <div v-else-if="getUser !== null" class="__kfc_r_8 login" @click="toggleProfileMode">
            <img src="@/assets/images/user.png" alt="" />
            <span>{{ getUser.phone }}</span>
          </div>
          <router-link to="/basket">
            <div class="basket">
              <span class="__kfc_r_8">{{ $t('basket') }}</span>
              <basket-icon />
            </div>
          </router-link>
        </div>
        <div class="language __kfc_r_8">
          <div class="border"></div>
          <span class="lang" @click="changeLang" v-if="locale === 'mn'">EN</span>
          <span class="lang" @click="changeLang" v-if="locale === 'en'">MN</span>
        </div>
      </div>
    </div>
  </div>
  <div class="border"></div>
  <Login v-if="login" @handleClose="loginPopup" />
</template>

<script>
import Login from "@/components/Popup/Login.vue";
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import BasketIcon from './BasketIcon.vue';
export default {
  components: {
    Login,
    BasketIcon,
  },
  props: {
    toggleProfileMode: Function,
  },
  setup () {
    const login = ref(false);
    const store = useStore();
    const cartCount = computed(() => store.getters['cart/getCartCount']);
    const getUser = computed(() => store.getters['user/getUser']);
    const locale = computed(() => store.getters.locale);
    const i18n = useI18n();
    const loginPopup = () => {
      login.value = !login.value;
    };
    const changeLang = () => {
      if (locale.value === 'en') {
        i18n.locale.value = 'mn'
        store.dispatch('setLocale', 'mn')
      }
      else {
        i18n.locale.value = 'en'
        store.dispatch('setLocale', 'en')
      }
    }
    return {
      login, getUser, locale, cartCount, loginPopup, changeLang
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 86px;

  .header_wrapper {
    // display: flex;
    align-items: center;
    // justify-content: space-between;
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 100px 1fr;

    .left-wrap {
      display: flex;
      gap: 32px;
      align-items: center;

      .order-wrap {
        border-radius: 4px;
        padding: 12px 22px;
        background: $dark3;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        img {
          width: 24px;
          height: 100%;
        }

        span {
          color: $dark40;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          /* 123.077% */
          letter-spacing: -0.3px;

          @media (min-width: 768px) and (max-width: 1024px) {
            display: none;
          }
        }
      }
    }

    .right-wrap {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      .right-items {
        display: flex;
        gap: 32px;

        .login {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

          span {
            color: $darkMain;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.3px;
          }
        }

        .basket {
          display: flex;
          gap: 15px;
          align-items: center;
          height: 100%;

          span {
            color: $darkMain;
            text-align: center;
          }
        }
      }

      .language {
        display: flex;
        align-items: center;
        height: 100%;

        &:hover {
          color: $redMain;
          transition: ease-in 0.1s;
        }

        .border {
          width: 1px;
          height: 20px;
          background: $dark12;
        }

        .lang {
          cursor: pointer;
          width: 2.2rem;
          text-align: right;
        }
      }
    }

    .header_layout_logo {
      align-self: start;

      a {
        img {
          height: 67px;
          width: auto;
          object-fit: contain;
        }
      }
    }
  }
}

.border {
  height: 20px;
  width: 100%;
  background: $redMain;
}
</style>
