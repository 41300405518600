const SERVER_PATH =
  'https://ruxxklfq53.execute-api.ap-southeast-1.amazonaws.com/production'; //webApi
  // 'http://192.168.230.160:8082';
  // 'http://192.168.230.115:8086';
const PAYMENT_PATH =
  'https://fj21l8pcpa.execute-api.ap-southeast-1.amazonaws.com/production';
  // 'http://192.168.230.160:8083';
const ORDER_PATH =
  'https://6kda0vgse0.execute-api.ap-southeast-1.amazonaws.com/production';
  // 'http://10.10.228.209:8086/setOrder';
const ADDRESS_PATH =
  'https://z4ryw4kny0.execute-api.ap-southeast-1.amazonaws.com/production';
// const SERVER2_PATH =
//   'https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production'; //
export default {
  SERVER_PATH: SERVER_PATH,
  PAYMENT_PATH: PAYMENT_PATH,
  ADDRESS_PATH: ADDRESS_PATH,
  ORDER_PATH: ORDER_PATH,
};
//getMyOrders

//updatePassword
// webhookInvoice/socialpay?invoice=0920Z1e5JTY&status_code=000&desc=%25D0%2590%25D0%25BC%25D0%25B6%25D0%25B8%25D0%25BB%25D1%2582%25D1%2582%25D0%25B0%25D0%25B9