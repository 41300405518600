let localLanguage = localStorage.getItem('language');

export const language = {
  // state
  state: {
    locale: localLanguage ? JSON.parse(localLanguage) : 'mn',
    locales: {
      en: localStorage.getItem('language') || 'en',
      mn: 'mn',
    },
  },

  // getters
  getters: {
    locale: (state) => state.locale,
    locales: (state) => state.locales,
  },

  // mutations
  mutations: {
    setLanguage(state, locale) {
      state.locale = locale;
      window.localStorage.setItem('language', JSON.stringify(state.locale));
    },
  },

  // actions
  actions: {
    setLocale(state, locale) {
      state.commit('setLanguage', locale);
    },
  },
};
