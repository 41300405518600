<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="footer">
    <div class="container">
      <div class="wrap_container">
        <div class="top">
          <div class="logo-wrap">
            <img src="@/assets/images/footer_logo.png" alt="logo" />
            <span class="__kfc_r_4">
              <!-- Хаанаас ч хайгаад оломгүй Шунан дурламаар амтыг Та зөвхөн KFC-ээс амтална. -->
              {{ $t('footer.desc') }}
            </span>
          </div>
          <div class="contact-wrap">
            <img src="@/assets/images/phone.png" alt="" />
            <span class="__kfc_r_4"> 7555-1010 </span>
          </div>
        </div>
        <div class="bottom_menu">
          <div class="menu_item" collapsed="true" ref="menu1">
            <div class="menu_item-header">
              <h5>
                {{ $t('footer.kfcmongolia') }}
              </h5>
              <img src="@/assets/icons/up.svg" alt="" @click="handleCollapse('menu1')">

            </div>
            <div class="menu">
              <router-link to="/history">
                {{ $t('history.name') }}
              </router-link>
              <router-link to="/quality_standards">
                {{ $t('qualityStandarts.name') }}
              </router-link>
            </div>
          </div>
          <div class="menu_item" collapsed="true" ref="menu2">
            <div class="menu_item-header">
              <h5>
                {{ $t('footer.socialRespo') }}
              </h5>
              <img src="@/assets/icons/up.svg" alt="" @click="handleCollapse('menu2')">

            </div>
            <div class="menu">
              <router-link to="/social_responsibility">
                {{ $t('huggingDay.name') }}
              </router-link>
              <a target="_blank" href="https://www.facebook.com/KFC.Mongolia">
                {{ $t('fillTheGap.name') }}
              </a>
              <router-link to="/addon">
               Тэтгэлэгт хөтөлбөр
              </router-link>
            </div>
          </div>
          <div class="menu_item" collapsed="true" ref="menu3">
            <div class="menu_item-header">
              <h5>
                {{ $t('footer.policies') }}
              </h5>
              <img src="@/assets/icons/up.svg" alt="" @click="handleCollapse('menu3')">

            </div>
            <div class="menu">
              <router-link to="/terms">
                {{ $t('termsOfUse.name') }}
              </router-link>
              <router-link to="/privacy">
                {{ $t('privacyPolicy.name') }}

              </router-link>
            </div>
          </div>
          <div class="menu_item" collapsed="true" ref="menu4">
            <div class="menu_item-header">
              <h5>
                {{ $t('footer.joinUs') }}
              </h5>
              <img src="@/assets/icons/up.svg" alt="" @click="handleCollapse('menu4')">

            </div>
            <div class="menu">
              <router-link to="/hr">{{ $t('getHired.name') }}</router-link>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="copyright">
            <!-- © 2023 KFC Монгол. Зохиогчийн эрх хуулиар хамгаалагдсан. Developed by Tavan
            Bogd Solutions LLC -->
            {{ $t('footer.foot') }}
          </div>
          <div class="social-wrap">
            <a href="https://www.facebook.com/KFC.Mongolia" target="_blank"><img src="@/assets/images/facebook.svg"
                alt="icon" /></a>
            <a href="https://www.instagram.com/kfc_mongolia/" target="_blank"><img src="@/assets/images/instagram.svg"
                alt="icon" /></a>
            <a href="https://twitter.com/MongoliaKfc" target="_blank"><img src="@/assets/images/twitter.svg"
                alt="icon" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';

export default {
  setup () {
    const menuItemsRefs = reactive({
      menu1: ref(),
      menu2: ref(),
      menu3: ref(),
      menu4: ref(),
    })
    // const menuItemsRefs = toRefs(menuItem);
    const handleCollapse = (name) => {
      if (menuItemsRefs[name].hasAttribute("collapsed")) {
        menuItemsRefs[name].removeAttribute("collapsed")
      } else {
        menuItemsRefs[name].setAttribute("collapsed", true)
      }
    }
    return {
      ...toRefs(menuItemsRefs), handleCollapse
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background: $dark90;
  position: relative;
  z-index: 11;

  .container {
    padding: 40px 0;

    .wrap_container {
      .top {
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark80;

        .logo-wrap {
          display: flex;
          align-items: center;
          gap: 30px;
          width: 50%;

          span {
            color: $whiteMain;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            /* 133.333% */
            letter-spacing: -0.3px;
          }
        }

        .contact-wrap {
          display: flex;
          align-items: center;
          gap: 16px;

          span {
            color: $whiteMain;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            /* 150% */
            letter-spacing: -0.3px;
          }
        }
      }

      .bottom_menu {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 60px 0 100px 0;
        gap: 24px;

        .menu_item {
          width: calc((100% - 72px) / 4);

          .menu_item-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            img {
              cursor: pointer;
              transform: rotate(180deg);
              transition: 0.2s ease-out;
            }
          }

          h5 {
            margin: 0 0 16px 0;
            padding: 0;
            color: $whiteMain;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            /* 160% */
            letter-spacing: -0.3px;
          }

          .menu {
            display: flex;
            flex-direction: column;
            gap: 6px;
            display: none;

            a {
              color: $whiteMain;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              /* 128.571% */
              letter-spacing: -0.3px;
              opacity: 0.7;
              text-decoration: none;
            }
          }
        }

        .menu_item[collapsed="true"] {
          .menu {
            display: flex;
          }

          .menu_item-header {
            img {
              display: none;
              transform: rotate(0);
            }
          }
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .copyright {
          color: $whiteMain;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          /* 123.077% */
          letter-spacing: -0.3px;
          opacity: 0.7;
        }

        .social-wrap {
          display: flex;
          gap: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 796px) {
  .footer {
    .container {
      padding: 40px 30px;

      .wrap_container {
        // .top {
        //   gap: 40px;
        // }

        .bottom_menu {
          .menu_item {
            width: calc((100% - 24px) / 2);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 586px) {
  .footer {
    .container {
      .wrap_container {
        .top {
          .logo-wrap {
            span {
              display: none;
            }
          }
        }

        .bottom_menu {
          .menu_item {
            width: 100%;

            .menu_item-header {
              img {
                display: block !important;
              }
            }
          }


        }

        .bottom {
          flex-direction: column;
          gap: 24px;

          .copyright {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
