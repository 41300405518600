const initialOptions = {
  origin: "",
  channel: "web",
  order_type: 1,
  advance_order: 0,
  promised_time: "",
  phone2: "",
  pickup_type: "",
  pay_type: "",
  pay_type_name: "",
  customer_note: "",
  vat_type: 1,
  vat_regno: "",
  vat_cust_name: "",
  employee: "",
  menu_total: 0,
  delivery_charge: 0,
  coupon_codes: [],
};
export const order = {
  // state
  namespaced: true,
  state: {
    cust: {
      phone: "",
      email: "",
      customerUser: "",
    },
    options: sessionStorage.getItem("checkoutOptions")
      ? JSON.parse(sessionStorage.getItem("checkoutOptions"))
      : initialOptions,
  },

  // getters
  getters: {
    getOptions(state) {
      return state.options;
    },
    getCust(state) {
      return state.cust;
    },
  },

  // mutations
  mutations: {
    UPDATE_OPTIONS(state, data) {
      Object.keys(data).forEach((key) => {
        state.options[key] = data[key];
      });
      sessionStorage.setItem("checkoutOptions", JSON.stringify(state.options));
    },
    UPDATE_CUST(state, data) {
      Object.keys(data).forEach((key) => {
        state.cust[key] = data[key];
      });
    },
    CLEAR_OPTIONS(state, data) {
      state.options = initialOptions;
      // if (data) {
      //   Object.keys(data).forEach((key) => {
      //     state.options[key] = data[key];
      //   });
      // }
      sessionStorage.removeItem("checkoutOptions");
    },
    CLEAR_CUST(state, data) {
      state.cust = {
        phone: "",
        email: "",
        customerUser: "",
      };
    },
  },

  // actions
  actions: {
    updateOptions(state, data) {
      state.commit("UPDATE_OPTIONS", data);
    },
    updateCust(state, data) {
      state.commit("UPDATE_CUST", data);
    },
    clearCust(state, data) {
      state.commit("CLEAR_CUST", data);
    },
    clearOptions(state, data) {
      state.commit("CLEAR_OPTIONS", data);
    },
  },
};
