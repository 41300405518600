import axios from "axios";
import configJson from "../config";
// import errorDetail from './helpers';

const Req = async ({
  method = "post",
  url,
  type = 1,
  hideError,
  hideSuccess,
  successMsg,
  body = {},
  disableLoader = true,
  jwtToken = null,
}) => {
  // const {
  //     accessToken: { jwtToken },
  // } = await Auth.currentSession();

  return new Promise(async (resolve, reject) => {
    const root = document.querySelector("#app");
    try {
      // START LOADER
      disableLoader && root.dispatchEvent(new CustomEvent("startloader"));
      let urlType = configJson.SERVER_PATH;
      if (type === 0) urlType = '';
      if (type === 1) urlType = configJson.SERVER_PATH;
      if (type === 2) urlType = configJson.PAYMENT_PATH;
      if (type === 3) urlType = configJson.ADDRESS_PATH;
      if (type === 4) urlType = configJson.ORDER_PATH;
      // const result = await axios[method](urlType + url)
      let axiosObject = [];
      if (method === "get") {
        axiosObject = [{ headers: { Authorization: `Bearer ${jwtToken}` } }];
      } else {
        axiosObject = [
          { ...body },
          { headers: { Authorization: `Bearer ${jwtToken}` } },
        ];
      }
      // if (jwtToken) {
      // }
      const result = await axios[method](urlType + url, ...axiosObject);
      resolve(result);
      if (result.data.success || result.data.status === "success") {
        if (hideSuccess) return;
        root.dispatchEvent(
          new CustomEvent("toastmessage", {
            detail: {
              type: "success",
              message: successMsg ?? "Амжилттай",
            },
          })
        );
      } else {
        if (hideError) return;
        root.dispatchEvent(
          new CustomEvent("toastmessage", {
            detail: { type: "error", message: result.data.message },
          })
        );
      }
    } catch (e) {
      //   const error = await errorDetail(e);
      reject(e);
      if (hideError) return;
      root.dispatchEvent(
        new CustomEvent("toastmessage", {
          detail: { type: "error", message: e },
        })
      );
    } finally {
      root.dispatchEvent(new CustomEvent("endloader"));
    }
  });
};

export default Req;
