<template>
    <div class="kfc_loader">
        <div class="__visibility_block"></div>
        <div class="__loader_wrap">
            <div class="__loader_image"><span></span></div>
            <div class="__loader_content">{{ $t('loading') }} ...</div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {

    }
}
</script>

<style scoped lang="scss">
.kfc_loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vw;
    width: 100vw;
    z-index: 999;

    .__visibility_block {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 400vw;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1;
    }

    .__loader_wrap {
        color: #fff;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;

        .__loader_image {
            display: flex;
            height: 80px;
            width: 80px;
            margin-bottom: 20px;

            span {
                display: flex;
                height: 100%;
                width: 100%;
                background: transparent url('@/assets/images/logo_1.png') no-repeat center center;
                background-size: contain;
                animation: infinite 2s loader_scale;
            }
        }

        .__loader_content {
            color: #fff;
            font-size: 14px;
            line-height: 1;
            display: flex;
            padding: 8px 12px;
            background-color: #000;
            border-radius: 20px;
        }
    }

    @keyframes loader_scale {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }
}
</style>