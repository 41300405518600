// import { createApp } from "vue";
import { createI18n } from 'vue-i18n';
// import App from "./App.vue";

// createApp(App).use(createI18n({ legacy: false }));

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export default new createI18n({
  locale: localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))
    : 'mn',
  fallbackLocale: localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))
    : 'mn',
  messages: loadLocaleMessages(),
  legacy: false,
  globalInjection: true,
});
