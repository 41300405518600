<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_overlay" ref="itself" @click="(e) => {
    e.target === itself && handleClose();
  }
    ">
    <div class="__kfc_popup_main_wrap main-wrap">
      <div class="closeImg">
        <img src="@/assets/images/close.svg" alt="close" @click="handleClose()" />
      </div>
      <div class="content">
        <div class="title">{{ $t('login') }}</div>
        <el-form @submit.prevent="submitForm(refForm)" ref="refForm" class="__kfc_login_form __kfc_form" label-position="top" label-width="100px" :rules="rules"
          :model="form" status-icon>

          <!-- <label for="phoneNumber">Утасны дугаар</label>
            <input type="number" id="phoneNumber" v-model="phoneNumber" /> -->
          <el-form-item :label="$t('phoneNo')" class="form_item" prop="phoneNo">
            <el-input class="__kfc_r_8" v-model="form.phoneNo" :placeholder="$t('phoneNo')" />
          </el-form-item>

          <el-form-item :label="$t('password')" class="form_item" prop="password">
            <el-input type="password" class="__kfc_r_8" v-model="form.password" :placeholder="$t('password')"
              show-password />
          </el-form-item>
          <!-- <label for="password">Нууц үг</label>
            <input type="password" id="password" v-model="password" /> -->

          <button class="__kfc_button __kfc_button_red" type="submit">
            {{ $t('login') }}
          </button>
        </el-form>
        <div class="bottom-wrap">
          <router-link to="/signup" @click="handleClose()">
            {{ $t('signup') }}
          </router-link>
          <router-link to="/password-recovery" @click="handleClose()">
            {{ $t('passwordRecovery') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Req from "@/utils/Request";
import { computed, inject, onMounted, onUnmounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default {
  setup(props, { emit }) {
    const notify = inject('notify');
    const store = useStore();
    const router = useRouter()
    const locale = computed(() => store.getters['locale']);
    const itself = ref(null);
    const refForm = ref(null);
    const form = reactive({
      phoneNo: '',
      password: '',
      addr_note: '',
    });
    const rules = reactive({
      phoneNo: [
        {
          required: true,
          message: locale.value == 'mn' ? 'Утасны дугаар заавал байх ёстой' : 'Phone number required',
          trigger: 'blur'
        },
        {
          required: true,
          len: 8,
          message: locale.value == 'mn' ? 'Утасны дугаараа зөв оруулна уу' : 'Phone number is wrong',
          trigger: 'change'
        }
      ],
      password: [
        {
          required: true,
          message: locale.value == 'mn'
            ? 'Нууц үг заавал байх ёстой'
            : 'Нууц үг заавал байх ёстой',
          trigger: 'change'
        }
      ],
    });
    const handleClose = () => {
      itself.value.classList.add("unfade");
      setTimeout(() => {
        emit("handleClose");
      }, 300);
    };
    const submitForm = async (formEl) => {
      if (!formEl)
        return;
      await formEl.validate(async (valid) => {
        if (valid) {
          const payload = {
            phone: form.phoneNo,
            password: form.password,
            remember: false
          }
          const { data: result } = await Req({ url: '/manager/login', body: payload })
          if (result.success) {
            const loggedUser = {
              id: result.result.id,
              name: result.result.name,
              phone: result.result.phone,
              email: result.result.email,
              access_token: result.result.access_token
            }
            store.dispatch('user/setUser', loggedUser)
            handleClose();
            router.push('/');
          }
        }
      });

    };
    onMounted(() => {
      document.querySelector('body').classList.add('__kfc_overflow_hidden');

    });
    onUnmounted(() => {
      document.querySelector('body').classList.remove('__kfc_overflow_hidden');
    })

    return {
      itself,
      form,
      rules,
      refForm,
      submitForm,
      handleClose
    };
  },
};
</script>

<style lang="scss" scoped>
.main-wrap {
  padding: 60px;
  width: 440px;

  .closeImg {
    align-self: flex-end;
  }

  .__kfc_login_form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .__kfc_button {
      padding: 20px;
      padding: 16px 30px;
      width: 80%;
      align-self: center;
      margin-top: 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    width: 100%;

    .title {
      color: $dark90;
      font-family: Oswald;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      /* 125% */
      text-transform: uppercase;
    }

    // form {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   width: 320px;

    //   label {
    //     margin: 0 30px 8px 30px;
    //     color: $dark40;
    //     font-size: 13px;
    //     font-weight: 500;
    //     line-height: 16px;
    //     /* 123.077% */
    //     letter-spacing: -0.3px;
    //     width: 100%;
    //   }

    //   input {
    //     margin: 0 30px 16px 30px;
    //     border-radius: 4px;
    //     background: $dark5;
    //     padding: 16px;
    //     border: none;
    //     color: $dark90;
    //     font-size: 14px;
    //     font-weight: 600;
    //     line-height: 18px;
    //     /* 128.571% */
    //     letter-spacing: -0.3px;
    //     width: 100%;

    //     &:focus {
    //       outline: none;
    //     }
    //   }

    //   button {
    //     margin-top: 24px;
    //     padding: 16px 30px;
    //     background: $redMain;
    //     border: none;
    //     color: $whiteMain;
    //     text-align: center;
    //     font-size: 15px;
    //     font-weight: 600;
    //     line-height: 18px;
    //     /* 120% */
    //     letter-spacing: -0.3px;
    //     border-radius: 4px;
    //     width: 70%;
    //     cursor: pointer;
    //   }
    // }

    .bottom-wrap {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 70%;

      a {
        text-decoration: none;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        /* 120% */
        letter-spacing: -0.3px;
        cursor: pointer;
        color: $dark90;

        &:first-of-type {
          color: $redMain;
        }
      }
    }
  }
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}
</style>
