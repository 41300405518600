<template>
    <div class="basket_count">
        <img src="@/assets/images/basket.svg" alt="" />
        <span class="__kfc_r_5 count">{{ cartCount }}</span>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    setup (props) {
        const store = useStore();
        const cartCount = computed(() => store.getters['cart/getCartCount']);
        return {
            cartCount
        }

    }
}
</script>

<style lang="scss" scoped>
.basket_count {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 28px;
        height: 38px;
    }

    .count {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkMain;
        /* 128.571% */
    }
}
</style>