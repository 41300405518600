import { createApp } from 'vue';
import Vuex from 'vuex';
import App from '../App.vue';

import { menu } from './menu';
import { address } from './address';
import { cart } from './cart';
import { order } from './order';
import { language } from './language';
import { user } from './user';

createApp(App).use(Vuex);

export const store = new Vuex.Store({
  modules: {
    menu,
    address,
    cart,
    order,
    user,
    language,
  },
});
