<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_overlay" ref="itself" @click="(e) => {
    e.target === itself && handleClose();
  }
    ">
    <div class="__kfc_popup_main_wrap">
      <div class="img">
        <img src="@/assets/gifs/exit-avatar.gif" alt="arrow" />
      </div>
      <div class="content">
        <div class="title">
          {{ $t('logout') }}!
        </div>
        <div class="description">
          <!-- Та гарахдаа итгэлтэй байна уу? -->
          {{ $t('logoutMessage') }}
        </div>
      </div>
      <div class="bottom-wrap">
        <button class="secondary" @click="togglePopupMode">{{ $t('no') }}</button>
        <button class="primary" @click="handleConfirm">{{ $t('yes') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    togglePopupMode: Function,
  },
  setup(props, { emit }) {
    const itself = ref(null);
    const handleClose = () => {
      itself.value.classList.add("unfade");
      setTimeout(() => {
        emit("handleClose");
      }, 300);
    };
    const handleConfirm = () => {
      emit("handleLogout");
    }
    return {
      itself,
      handleClose,
      handleConfirm
    };
  },
};
</script>

<style lang="scss" scoped>
.__kfc_popup_main_wrap {
  padding: 60px;

  .img {
    width: 150px;
    height: 150px;
    background: $dark90;
    border-radius: 50%;

    img {
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    color: var(--dark-90, #1a1a1a);
    text-align: center;
    font-family: Oswald;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    width: 400px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 630px) {
    .title {
      width: 300px;
    }
  }

  .description {
    color: $dark80;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 160% */
    letter-spacing: -0.3px;
  }
}

.bottom-wrap {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;

  .secondary {
    background: $dark12;
    border: none;
    color: $dark90;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    /* 120% */
    letter-spacing: -0.3px;
    border-radius: 4px;
    padding: 16px 30px;
    width: 50%;
  }

  .primary {
    padding: 16px 30px;
    background: $redMain;
    border: none;
    color: $whiteMain;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    /* 120% */
    letter-spacing: -0.3px;
    border-radius: 4px;
    width: 50%;
  }
}
</style>
