<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_right">
    <div class="top">
      <div class="arrow" @click="toggleProfileMode">
        <img src="@/assets/images/arrow-forward.svg" alt="arrow" />
      </div>
      <div class="wrapper">
        <div class="user-wrap">
          <div class="avatar">
            <img src="@/assets/images/avatar.png" alt="avatar" />
          </div>
          <div class="name">
            {{ getUser.name }}
          </div>
          <div class="contact-wrap">
            <div class="contact">
              <p>{{ $t('phoneNo') }}:</p>
              <p>{{ getUser.phone }}</p>
            </div>
            <div class="contact">
              <p>{{ $t('email') }}:</p>
              <p>{{ getUser.email }}</p>
            </div>
          </div>
        </div>
        <div class="settings">
          <div class="router" @click="toggleSettingMode">
            <h5>{{ $t('settings') }}</h5>
            <img src="@/assets/images/round-arrow.svg" alt="arrow" />
          </div>
          <div class="router" @click="toggleOrdersMode">
            <h5>{{ $t('myOrders.title') }}</h5>
            <img src="@/assets/images/round-arrow.svg" alt="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <button @click="togglePopupMode">{{ $t('logout') }}</button>
    </div>
  </div>
  <div class="popup" v-if="togglePopup">
    <Exit :togglePopupMode="togglePopupMode" @handleClose="togglePopupMode" @handleLogout="handleLogout" />
  </div>
</template>

<style lang="scss" scoped>
.__kfc_popup_right {
  .top {
    display: flex;
    flex-direction: column;
    gap: 96px;

    .arrow {
      cursor: pointer;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .user-wrap {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: $dark5;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
          }
        }

        .name {
          color: $darkMain;
          font-family: Oswald;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          /* 125% */
        }

        .contact-wrap {
          .contact {
            display: flex;
            gap: 10px;

            p {
              color: $dark80;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 160% */
              letter-spacing: -0.3px;

              &:first-of-type {
                flex-shrink: 0;
              }
            }
          }
        }
      }

      .settings {
        .router {
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px solid $dark12;
          cursor: pointer;

          h5 {
            margin: 0;
            padding: 0;
            color: $dark90;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            /* 120% */
            letter-spacing: -0.3px;
          }
        }
      }
    }
  }

  .bottom {
    padding: 0 40px;

    button {
      width: 100%;
      border: none;
      background: $darkMain;
      color: $whiteMain;
      padding: 16px 30px;
      cursor: pointer;
    }
  }
}

// slide-to-right

@media screen and (max-width: 630px) {
  .__kfc_popup_right {
    left: 0;
    min-width: 100%;
  }
}
</style>

<script>
import Exit from "@/components/Popup/Exit.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Exit,
  },
  props: [
    'toggleProfileMode',
    'toggleProfile',
    'toggleSettingMode',
    'toggleSetting',
    'toggleOrders',
    'toggleOrdersMode',
  ],
  setup (props, { emit }) {
    const {
      toggleProfileMode,
      toggleProfile,
      toggleSettingMode,
      toggleSetting,
      toggleOrders,
      toggleOrdersMode,
    } = props
    const store = useStore();
    const getUser = computed(() => store.getters['user/getUser']);
    const togglePopup = ref(false);
    const togglePopupMode = () => {
      togglePopup.value = !togglePopup.value;
    };
    const handleLogout = () => {
      store.dispatch('user/deleteUser');
      togglePopupMode()
      toggleProfileMode();
    }
    return {
      togglePopup,
      getUser,
      toggleProfileMode,
      toggleProfile,
      toggleSettingMode,
      toggleSetting,
      toggleOrders,
      toggleOrdersMode,
      togglePopupMode,
      handleLogout
    };
  },
};
</script>
