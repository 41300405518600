<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_right">
    <div class="top">
      <div class="arrow" @click="toggleSettingMode">
        <img src="@/assets/images/arrow-forward.svg" alt="arrow" />
      </div>
      <div class="wrapper">
        <div class="title">
          {{ $t('settings') }}
        </div>
        <div class="settings">
          <div class="router" @click="toggleInfoMode">
            <h5>
              {{ $t('perInformation') }}
            </h5>
            <img src="@/assets/images/round-arrow.svg" alt="arrow" />
          </div>
          <div class="router" @click="toggleContactMode">
            <h5>
              {{ $t('contInformation') }}
            </h5>
            <img src="@/assets/images/round-arrow.svg" alt="arrow" />
          </div>
          <div class="router" @click="togglePasswordMode">
            <h5>
              {{ $t('changePassword') }}
            </h5>
            <img src="@/assets/images/round-arrow.svg" alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.__kfc_popup_right {
  .top {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .title {
        color: $darkMain;
        font-family: Oswald;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        /* 125% */
        text-transform: uppercase;
      }

      .settings {
        .router {
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px solid $dark12;
          cursor: pointer;

          h5 {
            margin: 0;
            padding: 0;
            color: $dark90;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            /* 120% */
            letter-spacing: -0.3px;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    toggleSettingMode: Function,
    toggleSetting: Boolean,
    toggleInfoMode: Function,
    toggleInfo: Boolean,
    toggleContactMode: Function,
    toggleContact: Boolean,
    togglePasswordMode: Function,
    togglePassword: Boolean,
  },
};
</script>
