<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="desktop">
    <HeaderDesktop :toggleProfileMode="toggleProfileMode" />
  </div>
  <div class="mobile">
    <HeaderMobile :toggleProfileMode="toggleProfileMode" />
  </div>

  <router-link to="/basket">
    <div class="fixed-basket" :class="isAdded ? 'added' : ''">
      <basket-icon />
    </div>
  </router-link>
</template>

<script>
import HeaderDesktop from "@/components/HeaderDesktop.vue";
import HeaderMobile from "@/components/HeaderMobile.vue";
import { computed, nextTick, ref, watch } from "vue";
import { useStore } from "vuex";
import BasketIcon from './BasketIcon.vue';

export default {
  props: {
    toggleProfileMode: Function,
  },
  components: {
    HeaderDesktop,
    HeaderMobile,
    BasketIcon,
  },
  setup () {
    const store = useStore();
    const isAdded = ref(false);
    const cartCount = computed(() => store.getters['cart/getCartCount']);
    watch(cartCount, async () => {
      isAdded.value = true;
      await nextTick();
      setTimeout(() => {
        isAdded.value = false;
      }, 1000);

    })
    return {
      cartCount, isAdded
    }
  }
};
</script>
<style lang="scss" scoped>
.mobile {
  display: none;
}

.fixed-basket {
  position: fixed;
  height: 56px;
  width: 56px;
  background-color: #fff;
  border-radius: 50%;
  bottom: 12px;
  right: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-basket.added {
    animation: hithere 1s ease-out;

}

@keyframes hithere {
    30% {
        transform: scale(1.4);
    }

    40%,
    60% {
        transform: rotate(-20deg) scale(1.4);
    }

    50% {
        transform: rotate(20deg) scale(1.4);
    }

    70% {
        transform: rotate(0deg) scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 768px) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}
</style>
