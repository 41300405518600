<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div ref="rightContRef" class="right-container" @click="handleClick">
    <Setting v-if="toggleSetting" :toggleSettingMode="toggleSettingMode" :toggleSetting="toggleSetting"
      :toggleContactMode="toggleContactMode" :toggleContact="toggleContact" :toggleInfoMode="toggleInfoMode"
      :toggleInfo="toggleInfo" :togglePasswordMode="togglePasswordMode" :togglePassword="togglePassword" />
    <Info v-else-if="toggleInfo" :toggleInfoMode="toggleInfoMode" :toggleInfo="toggleInfo" />
    <Contact v-else-if="toggleContact" :toggleContactMode="toggleContactMode" :toggleContact="toggleContact" />
    <Password v-else-if="togglePassword" :togglePasswordMode="togglePasswordMode" :togglePassword="togglePassword" :toggleProfileMode="toggleProfileMode" />
    <Orders v-else-if="toggleOrders" :toggleOrdersMode="toggleOrdersMode" :toggleOrders="toggleOrders"
      :toggleProfileMode="toggleProfileMode" />
    <Main v-else :toggleProfileMode="toggleProfileMode" :toggleProfile="toggleProfile"
      :toggleSettingMode="toggleSettingMode" :toggleSetting="toggleSetting" :toggleOrders="toggleOrders"
      :toggleOrdersMode="toggleOrdersMode" @handleLogout=handleLogout />
  </div>
</template>

<script>
import Main from "./Main.vue";
import Setting from "./Setting.vue";
import Info from "./Info.vue";
import Contact from "./Contact.vue";
import Password from "./Password.vue";
import Orders from "./Orders.vue";
import { ref } from "vue";

export default {
  props: ['toggleProfileMode', 'toggleProfile'],
  components: {
    Main,
    Setting,
    Info,
    Contact,
    Password,
    Orders
  },
  setup(props) {
    const { toggleProfileMode, toggleProfile } = props;
    const toggleSetting = ref(false);
    const toggleInfo = ref(false);
    const toggleContact = ref(false);
    const togglePassword = ref(false);
    const toggleOrders = ref(false);
    const rightContRef = ref(null);
    const toggleSettingMode = () => {
      toggleSetting.value = !toggleSetting.value;
    };
    const toggleInfoMode = () => {
      toggleInfo.value = !toggleInfo.value;
      toggleSetting.value = !toggleSetting.value;
    };
    const toggleContactMode = () => {
      toggleContact.value = !toggleContact.value;
      toggleSetting.value = !toggleSetting.value;
    };
    const togglePasswordMode = () => {
      togglePassword.value = !togglePassword.value;
      toggleSetting.value = !toggleSetting.value;
    };
    const toggleOrdersMode = () => {
      toggleOrders.value = !toggleOrders.value;
    };
    const handleClick = (ev) => {
      if (ev.target === rightContRef.value) {
        document.querySelector('.__kfc_popup_right').classList.add('unfade');
        setTimeout(() => {
          toggleProfileMode();
        }, 200);
      }
    }
    return {
      rightContRef,
      toggleSetting,
      toggleInfo,
      toggleContact,
      togglePassword,
      toggleOrders,
      toggleProfile,
      toggleProfileMode,
      toggleSettingMode,
      toggleInfoMode,
      toggleContactMode,
      togglePasswordMode,
      toggleOrdersMode,
      handleClick
    };
  },
};
</script>
<style lang="scss" scoped>
.right-container {
  width: 100%;
  height: 100%;
}
</style>
