export const address = {
  namespaced: true,
  state: {
    defaultOutlet: "KFC-Virtual",
    orderType: sessionStorage.getItem("orderType")
      ? parseInt(sessionStorage.getItem("orderType"))
      : 1,
    deliveryOutletName: "KFC-Virtual",
    pickupOutletName: "KFC-Virtual",
    activeOutletName: "KFC-Virtual",
    deliveryAddress: {
      addr_orts: "",
      addr_orts_code: "",
      addr_haalga: "",
      addr_note: "",
      bairname: "",
      bairname2: "",
      bairnote: "",
      full_address: "",
      longitude: "",
      latitude: "",
      podcode: "",
      podph: "",
      podkfc: "",
      podtoktok: "",
    },
    // pickupAddress: '',
  },
  actions: {
    updateOrderType(context, type) {
      context.commit("UPDATE_ORDER_TYPE", type);
    },
    updateDeliveryOutletName(context, name) {
      context.commit("UPDATE_DELIVERY_OUTLET_NAME", name);
    },
    updateDeliveryAddress(context, address) {
      context.commit("UPDATE_DELIVERY_ADDRESS", address);
    },
    // updateDeliveryAddressOrtsHaalga(context, address) {
    //   context.commit('UPDATE_DELIVERY_ADDRESS_ORTS_HAALGA', address);
    // },
    updatePickupAddress(context, address) {
      context.commit("UPDATE_PICKUP_ADDRESS", address);
    },
    deleteDeliveryAddress(context) {
      context.commit("DELETE_DELIVERY_ADDRESS");
    },
    deletePickupAddress(context) {
      context.commit("DELETE_PICKUP_ADDRESS");
    },
  },
  mutations: {
    UPDATE_ORDER_TYPE(state, type) {
      state.orderType = type;
      sessionStorage.setItem("orderType", type);
    },
    UPDATE_DELIVERY_OUTLET_NAME(state, name) {
      state.deliveryOutletName = name;
      sessionStorage.setItem("deliveryOutletName", name);
    },
    UPDATE_DELIVERY_ADDRESS(state, address) {
      state.deliveryAddress = address;
      sessionStorage.setItem("deliveryAddress", JSON.stringify(address));
    },
    UPDATE_PICKUP_ADDRESS(state, address) {
      state.pickupOutletName = address;
      sessionStorage.setItem("pickupOutletName", address);
    },
    DELETE_DELIVERY_ADDRESS(state) {
      // state.deliveryAddress = {};
      state.deliveryAddress.full_address = "";
      state.deliveryAddress.bairname = "";
      state.deliveryAddress.bairnote = "";
      state.deliveryAddress.latitude = "";
      state.deliveryAddress.longitude = "";
      state.deliveryAddress.addr_orts = "";
      state.deliveryAddress.addr_orts_code = "";
      state.deliveryAddress.addr_haalga = "";
      state.deliveryAddress.addr_note = "";
      state.deliveryAddress.podKfc = "";
      sessionStorage.removeItem("deliveryAddress");
      sessionStorage.removeItem("deliveryOutletName");
    },
    DELETE_PICKUP_ADDRESS(state) {
      state.pickupOutletName = state.defaultOutlet;
      sessionStorage.removeItem("pickupOutletName");
    },
  },
  getters: {
    getDefaultOutlet(state) {
      return state.defaultOutlet;
    },
    getOrderType(state) {
      return state.orderType;
    },
    getDeliveryOutletName(state) {
      const deliveryOutletName = sessionStorage.getItem("deliveryOutletName");
      if (deliveryOutletName) {
        state.deliveryOutletName = deliveryOutletName;
      }
      return state.deliveryOutletName;
    },
    getDeliveryAddress(state) {
      const address = sessionStorage.getItem("deliveryAddress");
      if (address) {
        state.deliveryAddress = JSON.parse(address);
      }
      return state.deliveryAddress;
    },
    getPickupAddress(state) {
      const pickupOutletName = sessionStorage.getItem("pickupOutletName");
      if (pickupOutletName) {
        state.pickupOutletName = pickupOutletName;
      }
      return state.pickupOutletName;
    },
    getActiveOutlet(state, getters) {
      return state.orderType === 1
        ? getters.getDeliveryOutletName
        : getters.getPickupAddress;
    },
    hasDeliveryAddress(state) {
      return state.deliveryAddress.full_address.length === 0 ? false : true;
    },
    hasPickupAddress(state) {
      return state.pickupOutletName.length === 0 ||
        state.pickupOutletName === state.defaultOutlet
        ? false
        : true;
    },
  },
};
