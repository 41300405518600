<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="__kfc_popup_right">
    <div class="top">
      <div class="arrow" @click="toggleInfoMode">
        <img src="@/assets/images/arrow-forward.svg" alt="arrow" />
      </div>
      <div class="wrapper">
        <div class="title">
          {{ $t('perInformation') }}
        </div>
        <el-form @submit.prevent="handleSubmit(refForm)"  ref="refForm" class="__kfc_login_form __kfc_form" label-position="top" label-width="100px" :rules="rules"
          :model="form" status-icon>
          <el-form-item :label="$t('name')" class="form_item" prop="name">
            <el-input class="__kfc_r_8" v-model="form.name" :placeholder="$t('name')" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <button type="button" @click="handleSubmit(refForm)">
        {{ $t('save') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.__kfc_popup_right {
  .top {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .title {
        color: $darkMain;
        font-family: Oswald;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        /* 125% */
        text-transform: uppercase;
      }


    }
  }

  .bottom {
    padding: 0 40px;

    button {
      width: 100%;
      border: none;
      background: $redMain;
      color: $whiteMain;
      border-radius: 4px;
      padding: 16px 30px;
    }
  }
}
</style>

<script>
import Req from "@/utils/Request";
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    toggleInfoMode: Function,
    toggleInfo: Boolean,
  },
  setup() {
    const store = useStore();
    const getUser = computed(() => store.getters['user/getUser']);
    const locale = computed(() => store.getters['locale']);
    const refForm = ref(null);
    const form = reactive({
      name: getUser.value.name,
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: locale.value == 'mn'
            ? 'Нэр заавал байх ёстой'
            : 'Name required',
          trigger: 'change'
        }
      ],
    });
    const handleSubmit = async (formEl) => {
      if (!formEl)
        return;
      await formEl.validate(async (valid) => {
        if (valid) {
          const payload = {
            id: getUser.value.id,
            name: form.name,
          }
          const { data: result } = await Req({ url: '/manager/setInfo', body: payload, jwtToken: getUser.value.access_token })
          if (result.success) {
            //   const loggedUser = {
            //     name: result.result.name,
            //     phone: result.result.phone,
            //     email: result.result.email
            //   }
            store.dispatch('user/updateUser', {
              name: result.result.name
            })
            //   handleClose();
            //   // router.push('/basket');
          }
        }
      });

    }
    return {
      form, rules, locale, refForm, handleSubmit
    }
  }
};
</script>
